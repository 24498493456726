import { Fragment, useMemo, useState } from "react"
import { Typography, Dialog, FormControl, InputLabel, MenuItem, Radio, Select } from "@mui/material"
import TextInput from "./TextInput"
import { Box, Button } from "@mui/material"
import CommitteeDisclaimer from "../Icons/CommitteeDisclaimer"
import { dialog_style, end_flex_buttons, inline_buttons } from "../../Utils/defaultStyles"
import { Add } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

const css = {
	dialog: {
		...dialog_style,
		'& > *': {
			margin: '12px 0'
		}
	},
	option: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		maxHeight: 300,
		overflowX: 'auto'
	}
}

function findTextOfAReactNode(node) {
	if (!node) return ""
	if (typeof node === 'string') return node
	if (node.props && node.props.children) {
		let text = ""
		for (let child of node.props.children) {
			text += findTextOfAReactNode(child)
		}
		return " " + text
	}
	return ""

}


export default function SelectInput({ options = [], groupBy = (option) => option.isSupervised !== undefined ? option.isSupervised ? "Supervisado" : "Otro" : "", label, name, disabled = false, required, inputStyle, onChange, enableCreate, createCallback, big, value }) {
	const [search, setSearch] = useState("")
	const [openDialog, setOpenDialog] = useState(false)
	const { t } = useTranslation("input")
	function handleSearch(event) {
		setSearch(event.target.value)
	}

	function handleCreateOption() {
		setOpenDialog(false)
		createCallback()
	}

	function handleOpenDialog() {
		setOpenDialog(!openDialog)
	}

	function handleChangeLong(option) {

		return () => {
			const originalValue = value

			if (originalValue === option.value) {
				onChange({
					target: {
						name,
						value: "",
					},
				})
				return
			}

			const updatedEvent = {
				target: {
					name,
					value: option.value,
				},
			}
			onChange(updatedEvent)
			handleOpenDialog()
		}
	}


	function renderMenu() {
		return options.map((option) => (
			<MenuItem value={option.value} key={option.label} sx={{ fontSize: big ? 15 : "unset", padding: 3 }}>
				<Box style={{ display: "flex", alignItems: "center" }}>
					{option.color && (
						<div
							style={{
								height: 12,
								width: 12,
								background: option.color,
								borderRadius: "50%",
								marginRight: 12,
							}}
						/>
					)}
					{option.is_committee && <CommitteeDisclaimer />}
					<Typography variant="subtitle1" style={{ lineHeight: 1 }}>
						{option.label}
					</Typography>
				</Box>
			</MenuItem>
		));
	}

	function renderLongMenu() {

		const filteredOptions = options.filter(option => {
			// Option may be a string or a react element
			if (typeof option?.label === 'string') return option?.label?.toLowerCase().includes(search?.toLocaleLowerCase())
			const label = findTextOfAReactNode(option?.label)
			return label.toLowerCase().includes(search?.toLocaleLowerCase())
		})
		const groups = Array.from(new Set(options.map(groupBy)))
		return (
			<Dialog open={openDialog} onClose={handleOpenDialog} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h1'>{t('actions.selectLabel', { label: label })}</Typography>
					<TextInput
						label={t('selectCreateInput.searchLabel')}
						placeholder={t('selectCreateInput.searchPlaceholder')}
						value={search}
						onChange={handleSearch}
					/>
					{groups.map((group, gindex) => {
						const groupOptions = filteredOptions.filter(option => groupBy(option) === group).sort((a, b) => {
							if (a.label > b.label) return 1
							if (a.label < b.label) return -1
							return 0
						})
						return (
							<Fragment key={gindex}>
								<Typography variant='h4'>{group}</Typography>
								<Box sx={css.options}>
									{groupOptions.map((option, index) => {
										const isSelected = option.value === sValue
										return (
											<Box key={option.label + index} sx={inline_buttons} onClick={handleChangeLong(option)}>
												<Radio checked={isSelected} />
												{!!option.is_committee && <CommitteeDisclaimer />}
												<Typography variant='subtitle1'>{option.label}</Typography>
											</Box>
										)
									})}
								</Box>
							</Fragment>
						)
					})}
					{enableCreate &&
						<Box sx={end_flex_buttons}>
							<Button color="info" variant="outlined" endIcon={<Add />} onClick={handleCreateOption}>
								{t('actions.createNewOption')}
							</Button>
						</Box>
					}
				</Box>
			</Dialog>

		)
	}


	const sValue = useMemo(() => value ? value + "" : "", [value])

	if (!options.length) return null
	if (options.length < 10) {
		return (
			<FormControl fullWidth style={{ margin: "12px 0" }}>
				<InputLabel shrink variant="outlined" >
					<Typography sx={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>
				</InputLabel>
				<Select
					label={label}
					value={sValue || ""}
					fullWidth
					name={String(name)}
					onChange={onChange}
					variant="outlined"
					displayEmpty
					disabled={disabled}
					sx={inputStyle}
				>
					{renderMenu()}
					{enableCreate &&
						<Box sx={end_flex_buttons}>
							<Button color="info" variant="outlined" endIcon={<Add />} onClick={handleCreateOption}>
								{t('actions.createNewOption')}
							</Button>
						</Box>
					}
				</Select>
			</FormControl>
		)
	}

	return (
		<>
			<FormControl fullWidth style={{ margin: "12px 0" }}>
				<Select
					value={sValue || ""}
					fullWidth
					name={name}
					onChange={onChange}
					variant="outlined"
					displayEmpty
					disabled
					sx={inputStyle}
					onClick={!disabled ? handleOpenDialog : null}
				>
					{renderMenu()}
				</Select>
				<InputLabel shrink variant="outlined">
					<Typography sx={{ fontWeight: required ? 600 : 400, lineHeight: 1 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>
				</InputLabel>
			</FormControl>
			{renderLongMenu()}
		</>
	)
}
