export function AdminPermissionList(t) {
    return [
        {
            section: t('titles.admin'), actions: [
                { name: t('actions.updatePermissions'), label: "sa" }
            ]
        },
        {
            section: t('titles.activities'), actions: [
                { name: t('actions.planActivities'), label: "pact" },
                { name: t('actions.editActivities'), label: "eact" }
            ]
        },
        {
            section: t('titles.enterprise'), actions: [
                { name: t('actions.createBranch'), label: "cbr" },
                { name: t('actions.createGroups'), label: "ebrt" },
                { name: t('actions.associateGroups'), label: "abrt" },
                { name: t('actions.sendCampaign'), label: "ecc" },
            ],
        },
        {
            section: t('titles.positions'), actions: [
                { name: t('actions.createPosition'), label: "c_position" },
                { name: t('actions.editPosition'), label: "e_position" },
                { name: t('actions.deletePosition'), label: "d_position" },
            ]
        },
        {
            section: t('titles.workers'), actions: [
                { name: t('actions.createWorker'), label: "cw" },
                { name: t('actions.editWorker'), label: "ew" }
            ]
        },
        {
            section: t('titles.checklists'), actions: [
                { name: t('actions.createChecklist'), label: "cch" },
                { name: t('actions.deleteChecklist'), label: "dch" },
            ]
        },
        {
            section: t('titles.findings'), actions: [
                { name: t('actions.approveRejectFinding'), label: "arf" },
            ]
        },
        {
            section: t('titles.reports'), actions: [
                { name: t('actions.createEditGraphs'), label: "cgc" },
            ]
        },
        {
            section: t('titles.workers'), actions: [
                { name: t('actions.changeEmail'), label: "cwm" },
            ]
        }
    ]
}

export function SupervisorPermissionList(t) {
    return [
        {
            section: t('titles.workers'), actions: [
                { name: t('actions.createWorker'), label: "cw" },
            ]
        },
        {
            section: t('titles.checklists'), actions: [
                { name: t('actions.createChecklist'), label: "cch" },
            ]
        },
        {
            section: t('titles.findings'), actions: [
                { name: t('actions.approveRejectFinding'), label: "arf" },
            ]
        },
    ]
}

export const permissions_dict = {
    "create_activity": "pact",
    "edit_activity": "eact",
    "create_branch": "cbr",
    "send_email_campaign": "ecc",
    "create_position": "c_position",
    "edit_position": "e_position",
    "delete_position": "d_position",
    "create_tag": "ctag",
    "edit_tag": "etag",
    "delete_tag": "dtag",
    "create_worker": "cw",
    "edit_worker": "ew",
    "create_checklist": "cch",
    "delete_checklist": "dch",
    "approve_findings": "appf",
    "super_admin": "sa",
    "create_branch_tag": "cbrt",
    "assign_branch_tag": "abrt",
    "change_worker_email": "cwm",
}