import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import TextInput from '../Inputs/TextInput'
import RutInput from '../Inputs/RutInput'
import SelectInput from '../Inputs/SelectInput'
import MultiSelectInput from '../Inputs/MultiSelectInput'
import RTE from '../Inputs/RTE'
import ImageContainer from '../Containers/ImageContainer'
import DateInput from '../Inputs/DateInput'
import PlainImageInput from '../Inputs/PlainImageInput'
import MultipleImageContainer from '../Containers/MultipleImageContainer'
import newTheme from '../../newTheme'
import { useTranslation } from 'react-i18next'

const css = {
	input: {
		'& > *': {
			margin: "0 !important"
		}
	},
	field: {
		marginBottom: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	},
	option: {
		background: 'whitesmoke',
		padding: '6px 12px',
		borderRadius: 1
	},
	photo: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 1
	}
}

function transformToExtraOptions(options) {
	if (!options) return []
	return options.map(option => {
		if (typeof option === "string") {
			return {
				label: option.split('&')[0],
				value: option.split('&')[0]
			}
		}
		return { label: option?.label || "", value: option?.label || "" }
	})
}

function getArray(value) {
	if (value === "") return []
	return JSON.parse(value)
}

function TextField({ onChange, value, onBlur, finished }) {
	const { t } = useTranslation('fields')
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<TextInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function RutField({ onChange, value, onBlur, finished }) {
	const { t } = useTranslation('fields')
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<RutInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function SelectField({ field, value, onChangeAndSave, finished, updateField }) {
	const { t } = useTranslation('fields')
	const options = field?.options || []
	const selectedOption = options.find(option => option.label === value)
	const subquestions = selectedOption?.subquestions || []
	const selectedOptionPhoto = selectedOption?.photo || ""

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{selectedOption ? selectedOption?.label : t('withoutAnswer')}</Typography>
			{!!selectedOptionPhoto &&
				<Box sx={css.photo}>
					<ImageContainer src={selectedOptionPhoto} width={64} height={64} alt="FOTO" border="8px" />
				</Box>
			}
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <GeneralField field={subquestion} finished={finished} updateField={updateField} />)}
			</Box>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<SelectInput value={value} onChange={onChangeAndSave} label="" options={transformToExtraOptions(options)} />
			{!!selectedOptionPhoto &&
				<Box sx={css.photo}>
					<ImageContainer src={selectedOptionPhoto} width={64} height={64} alt="FOTO" border="8px" />
				</Box>
			}
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <GeneralField field={subquestion} updateField={updateField} />)}
			</Box>
		</Box>
	)
}

function MultiField({ field, onChange, value = [], onBlur, finished }) {
	const { t } = useTranslation('fields')
	const options = field?.options || []
	const real_value = Array.isArray(value) ? value : getArray(value)


	if (finished) return (
		<Box sx={css.input}>
			<Box sx={css.options}>
				{!!real_value?.length ?
					real_value.map(val => <Typography sx={css.option} variant='subtitle1'>{val}</Typography>) :
					<Typography variant='subtitle1'>{t('withoutAnswer')}</Typography>
				}
			</Box>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<MultiSelectInput onBlur={onBlur} value={real_value} onChange={onChange} label="" options={transformToExtraOptions(options)} />
		</Box>
	)
}

function RTEField({ onChange, value, onBlur, finished }) {

	return (
		<Box style={{ maxWidth: '99%' }}>
			<RTE disabled={finished} onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>

	)
}

function ImageField({ field, value, onChangeAndSave, finished }) {
	const { t } = useTranslation('fields')

	const images = typeof value === "string" ? value.split("&#&") : ""
	const base_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
	if (finished) return (
		<Box sx={css.input}>
			{!!images.length ?
				<MultipleImageContainer
					border="6px"
					base_url={base_url}
					images={images}
					width={64}
					height={64}
					align='left'
				/>
				:
				<Typography variant='subtitle1'>{t('withoutAnswer')}</Typography>
			}
		</Box>
	)

	return (
		<Box sx={css.input}>
			<PlainImageInput
				value={value}
				name={field.label}
				onChange={onChangeAndSave}
				label=""
				url={"/"}
			/>
		</Box>
	)
}

function DateField({ onChangeAndSave, value, finished }) {
	const { t } = useTranslation('fields')
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<DateInput value={value} onChange={onChangeAndSave} label="" />
		</Box>
	)
}

function NumberField({ onChange, value, onBlur, finished }) {
	const { t } = useTranslation('fields')
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<TextInput type="number" onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}


function GeneralField({ field, updateField, finished }) {
	let ToRender = TextField
	if (field.field_type === "text") { ToRender = TextField }
	if (field.field_type === "rut") { ToRender = RutField }
	if (field.field_type === "select") { ToRender = SelectField }
	if (field.field_type === "multiselect") { ToRender = MultiField }
	if (field.field_type === "rte") { ToRender = RTEField }
	if (field.field_type === "img") { ToRender = ImageField }
	if (field.field_type === "number") { ToRender = NumberField }
	if (field.field_type === "date") { ToRender = DateField }

	const [value, setValue] = useState(field.value)

	useEffect(() => {
		setValue(field.value)
	}, [field])

	function onChange(event) {
		const { target } = event
		setValue(target.value)
	}

	async function onBlur(e, forced_value = null) {
		const new_value = !!forced_value && typeof forced_value === "string" ? forced_value : value
		const body = { ...field, value: new_value }
		updateField(body)
	}

	async function onChangeAndSave(event) {
		const { target } = event
		setValue(target.value)
		const body = { ...field, value: target.value }
		updateField(body)
	}

	return (
		<Box sx={css.field} >
			<Typography variant='subtitle1'><strong>{`${field.label}${field?.required ? " *" : ""}`}</strong></Typography>
			<ToRender finished={finished} onBlur={onBlur} field={field} value={value} onChange={onChange} onChangeAndSave={onChangeAndSave} updateField={updateField} />
		</Box>
	)
}

export default GeneralField