import { Box, Typography } from '@mui/material'
import { inline_buttons } from '../Utils/defaultStyles'
import CommitteeDisclaimer from './Icons/CommitteeDisclaimer'

function EntityTitle({ title = "título", variant = 'h4', color, belongs }) {
	return (
		<Box sx={inline_buttons} maxWidth={"80%"}>
			{!!belongs && <CommitteeDisclaimer />}
			<Typography variant={variant} color={belongs ? "celeste.main" : color}>{title}</Typography>
		</Box>
	)
}

export default EntityTitle