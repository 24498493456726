import React, { useState, useEffect } from 'react'
import {
	Dialog, FormControl, IconButton, InputAdornment, TextField, Typography,
	Button, Checkbox, Box,
	InputLabel
} from '@mui/material'
import { Clear, HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import TextInput from './TextInput'
import MultiGroups from './InputUtils/MultiGroups'
import SeamlessInput from './SeamlessInput'
import newTheme from '../../newTheme'
import { useTranslation } from 'react-i18next'
import { inline_space, flex_center } from '../../Utils/defaultStyles'

const css = {
	dialog: {
		padding: 2,
		'& > *': {
			margin: '12px 0',
		},
	},
	optionsContainer: {
		maxHeight: 300,
		overflow: 'auto',
	},
	selection: {
		background: newTheme.palette.background.main,
		borderRadius: 1,
	},
	option: {
		background: newTheme.palette.background.main,
		padding: "2px 8px",
		borderRadius: 1,
		...flex_center,
		gap: 1
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 1,
		maxHeight: 75,
		overflowY: 'auto',
	},
}

function sortOptions(a, b) {
	if (a.label > b.label) return 1
	if (a.label < b.label) return -1
	return 0
}

function MultiSelectInput(props) {
	const [search, setSearch] = useState('')
	const [comments, setComments] = useState({})
	const [selectAll, setSelectAll] = useState(false)
	const [openSelect, setOpenSelect] = useState(false)
	const { t } = useTranslation("input")
	const { value, onChange, options = [], label, grouped = (option) => "", hasComments, name, required } = props
	const rValue = Array.isArray(value) ? value : []

	useEffect(() => {
		const rValue = Array.isArray(value) ? value : []
		const newMessages = {}
		rValue.forEach(v => {
			const [option, message] = v?.toString().split(':')
			if (!!message) {
				newMessages[option] = message
			}
		})
		setComments(newMessages)
	}, [value])

	function handleSearch(event) {
		setSearch(event.target.value)
	}

	function handleFilterSearch(option) {
		if (!option.label) return false
		return option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
	}

	function handleChangeMessage(event) {
		const { target } = event
		const newComments = { ...comments, [target.name]: target.value }
		setComments(newComments)

		const cleanedValues = rValue.map(v => v.toString().split(':')[0])
		const finalValue = cleanedValues.map(v => `${v}:${newComments[v]}`)

		const newEvent = {
			target: {
				name,
				value: finalValue,
			},
		}

		onChange(newEvent)
	}

	const handleChange = (option) => () => {
		const cleanedValues = rValue.map(v => v.toString().split(':')[0])
		if (cleanedValues.includes(option.value)) {
			const newValue = rValue.filter(v => v.toString().split(':')[0] !== option.value)
			setSelectAll(false)
			const event = { target: { name, value: newValue } }
			onChange(event)
			return
		}
		const newValue = [...rValue, option.value]
		const event = { target: { name, value: newValue } }
		onChange(event)
	}

	const handleSelectAll = () => {
		if (selectAll) {
			const event = { target: { name, value: [] } };
			setSelectAll(false);
			onChange(event);
		} else {
			const nonEmptyValues = options.filter(option => option.value !== '').map(option => option.value);
			const event = { target: { name, value: nonEmptyValues } };
			setSelectAll(true);
			onChange(event);
		}
	};

	function handleCloseMenu() {
		const { onBlur } = props
		const event = { target: { name, value } }
		onBlur && onBlur(event)
		setOpenSelect(false)
	}

	const onSelectGroup = (group) => () => {
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])
		const filteredOptions = options.filter(handleFilterSearch).sort(sortOptions)
		const groupOptions = filteredOptions.filter(option => grouped(option).some(g => g === group))
		const groupValues = groupOptions.map(option => option.value)
		const allSelected = groupValues.every(v => cleanedValues.includes(v))
		const newValues = allSelected
			? cleanedValues.filter(v => !groupValues.includes(v))
			: [...new Set([...cleanedValues, ...groupValues])]
		const finalValue = newValues.map(v => `${v}:${comments[v]}`)
		const newEvent = {
			target: {
				name,
				value: finalValue
			}
		}
		onChange(newEvent)
	}


	function renderGroupedOptions() {
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])
		const filteredOptions = options.filter(handleFilterSearch).sort(sortOptions)
		const groups = Array.from(new Set(filteredOptions.map(grouped).flat(Infinity)))
		return (
			<Box sx={css.optionsContainer}>
				{groups.map((group, gindex) => {
					const groupOptions = filteredOptions.filter(option => Array.isArray(grouped(option)) ? grouped(option).some(g => g === group) : grouped(option) === group)
					return <MultiGroups key={gindex} value={cleanedValues} group={group} options={groupOptions} onChange={handleChange} onSelectGroup={onSelectGroup} />;
				})}
			</Box>
		)
	}

	const renderSelected = () => {
		const cleanedValues = rValue.map(v => v.toString().split(':')[0]);
		const selectedOptions = options.filter(option => cleanedValues.includes(option.value));
		return (
			<Box sx={css.options}>
				{selectedOptions.map((option) => (
					<Box key={option.value} sx={css.option}>
						<Typography variant='subtitle1'>{option.label}</Typography>
						{hasComments &&
							<SeamlessInput value={comments[option.value]} placeholder={t('data.comment')} name={option.value} onChange={handleChangeMessage} />
						}
						<IconButton size="small" onClick={handleChange(option)}>
							<Clear />
						</IconButton>
					</Box>
				))}
			</Box>
		)
	}

	const transformValues = (value) => {
		const cleanedValues = rValue.map(v => v.toString().split(':')[0])
		const selectedNames = options.filter(option => cleanedValues.includes(option.value)).map(o => o.label)
		return selectedNames.join(', ')
	}

	return (
		<>
			<FormControl fullWidth style={{ margin: '12px 0' }}>

				<TextField
					value={transformValues(rValue) || []}
					fullWidth
					label={label}
					onChange={onChange}
					variant="outlined"
					multiple
					required={required}
					onClick={() => setOpenSelect(true)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<KeyboardArrowDown />
							</InputAdornment>
						),
					}}
				/>
				<Dialog open={openSelect} onClose={handleCloseMenu} fullWidth maxWidth="sm">
					<Box sx={css.dialog}>
						<Box sx={inline_space}>
							<Typography variant='h1'>{t('title.selectLabel', { label: label })}</Typography>
							<IconButton size='small' onClick={handleCloseMenu}>
								<HighlightOffOutlined />
							</IconButton>
						</Box>
						<TextInput value={search} onChange={handleSearch} label={t('actions.searchLabel', { label: label })} />
						{renderSelected()}
						<Box sx={{ ...css.option, background: "" }} onClick={handleSelectAll}>
							<Checkbox checked={selectAll} />
							<Typography variant='subtitle1'>{t('actions.selectAll')}</Typography>
						</Box>
						{renderGroupedOptions()}
						<Box style={{ textAlign: 'right' }}>
							<Button variant="contained" color="primary" onClick={handleCloseMenu}>{t('actions.saveAndFinish')}</Button>
						</Box>
					</Box>
				</Dialog>
			</FormControl>
		</>
	);
};


export default MultiSelectInput
