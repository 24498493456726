import React, { useEffect, useState } from "react"
import { TextField, Typography } from "@mui/material"
import { getUserByRut } from '../../API/users'
import { useTranslation } from "react-i18next"


export default function RutInput({ value, label, name, type, disabled, required, onBlur, autoFocus, setBlock, onChange, options = {} }) {
    const [valid, setValid] = useState(true)
    const [user, setUser] = useState("")

    const isUserValid = typeof user !== "string"
    const { t } = useTranslation("input")

    useEffect(() => {
        if (value) {
            handleRutVerification(value)
        }
    }, [])


    function formatRut(value) {
        const replacedValue = value.replaceAll(".", "").replace("-", "")
        if (replacedValue.length < 7) return replacedValue

        const cuerpo = replacedValue.slice(0, -1)
        const dv = replacedValue.slice(-1).toUpperCase()
        return cuerpo + "-" + dv
    }


    function validateRut(rut) {
        const replacedValue = rut.replaceAll(".", "").replace("-", "")
        const cuerpo = replacedValue.slice(0, -1)
        const dv = replacedValue.slice(-1).toUpperCase()

        let suma = 0
        let multiplo = 2

        for (let i = 1; i <= cuerpo.length; i++) {
            let index = multiplo * replacedValue.charAt(cuerpo.length - i)
            suma += index
            multiplo = multiplo < 7 ? multiplo + 1 : 2
        }

        const dvEsperado = 11 - (suma % 11)
        let dvalue = parseInt(dv, 10)
        if (dv === 'K') dvalue = 10
        if (dv === '0') dvalue = 11

        return dvEsperado === dvalue
    }

    function handleChange(event) {
        const { target } = event
        const { name, value: inputValue } = target

        const sanitizedValue = inputValue.replace(/[^0-9kK]/g, "")
        if (sanitizedValue.length > 9) return

        const formattedValue = formatRut(sanitizedValue)
        const newEvent = {
            target: {
                name,
                value: sanitizedValue.trim() === "" ? undefined : formattedValue,
            },
        }
        onChange(newEvent)
        handleRutVerification(formattedValue)
    }

    async function handleRutVerification(value) {
        const formattedRut = formatRut(value)
        const isValid = validateRut(formattedRut)
        setUser("")
        setValid(isValid)
        if (setBlock) {
            setBlock(!isValid)
        }
        if (isValid) {
            await handleGetWorker(formattedRut)
            if (setBlock && !user) {
                setBlock(false)
            }
        }
    }

    async function handleGetWorker() {
        const response = await getUserByRut({ rut: value })
        const { data: { info: user } } = response
        setUser(user)
        if (user === "Usuario no existe") {
            const event = new CustomEvent("userbyid", { detail: { rut: value } })
            return window.dispatchEvent(event)
        }
        else {
            const event = new CustomEvent("userbyid", { detail: user })
            if (setBlock) {
                setBlock(true)
            }
            return window.dispatchEvent(event)
        }
    }

    return (
        <>
            <TextField
                value={value || ""}
                disabled={disabled}
                onChange={handleChange}
                label={<Typography style={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
                name={String(name)}
                variant="outlined"
                fullWidth
                style={{ margin: "12px 0" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type={type}
                rows={options.rows}
                onBlur={onBlur}
                autoFocus={autoFocus}
                multiline={options.multiline || false}
                placeholder={options.placeholder}
            />
            {!valid && <Typography variant="caption" style={{ color: 'red' }}>{t('feedback.invalidRut')}</Typography>}
            {isUserValid && <div style={{ marginBottom: 12, marginTop: -12 }}>
                <Typography variant="subtitle1" style={{ fontWeight: 600, fontStyle: "italic" }}>
                    {user.name} - {user.position}
                </Typography>
            </div>}
        </>
    )
}