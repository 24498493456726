import { useTranslation } from "react-i18next"

export function EducationLevels() {
	const { t } = useTranslation('createUserDialog')
	return [
		{ value: 1, label: t('educationLevel.basicEducation') },
		{ value: 2, label: t('educationLevel.secondaryEducation') },
		{ value: 3, label: t('educationLevel.technical') },
		{ value: 4, label: t('educationLevel.professionalDegree') },
		{ value: 5, label: t('educationLevel.master') },
		{ value: 6, label: t('educationLevel.phd') },
		{ value: 7, label: t('educationLevel.other') }
	]
}

export const userTypes = ["Administrador", "Supervisor", "Colaborador"]